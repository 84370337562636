import type { Config } from "tailwindcss";

import plugin from "tailwindcss/plugin";

const config: Config = {
    content: [
        "./src/modules/**/*.{js,ts,jsx,tsx,mdx}",
        "./src/app/**/*.{js,ts,jsx,tsx,mdx}",
    ],
    darkMode: "class",
    theme: {
        extend: {
            backgroundImage: {
                "gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
                "gradient-radial-half":
                    "radial-gradient(50% 50% at 50% 50%, var(--tw-gradient-stops))",
                "gradient-01":
                    "linear-gradient(94.95deg, #CFD1F5 0%, #FFFFFF 66.92%, #DBDDFF 97.23%)",
                "gradient-02":
                    "linear-gradient(180deg, #3E3E66 0%, #1D1D39 100%)",
                "gradient-03":
                    "linear-gradient(180deg, #FFFFFF 0%, #AEBCE1 94.3%)",
            },
            transitionDuration: {
                DEFAULT: "350ms",
            },
        },
        screens: {
            xs: "576px",
            sm: "768px",
            md: "1025px",
            lg: "1280px",
            xl: "1440px",
            "2xl": "1920px",
            "3xl": "2560px",
        },
        fontFamily: {
            sans: ["var(--font-inter)"],
        },
        fontSize: {
            h1: ["120px", "95%"],
            "h1-m": ["56px", "95%"],
            h2: ["60px", "100%"],
            "h2-m": ["36px", "100%"],
            h3: ["48px", "100%"],
            "h3-m": ["32px", "100%"],
            h4: ["40px", "110%"],
            "h4-m": ["28px", "110%"],
            h5: ["20px", "120%"],
            p1: ["18px", "120%"],
            "p1-m": ["16px", "120%"],
            p2: ["16px", "120%"],
            p3: ["14px", "120%"],
            mini: ["12px", "120%"],
        },
        colors: {
            transparent: "transparent",
            current: "currentColor",
            "total-black": "#000000",
            black: "#10121B",
            white: "#ffffff",
            steel: {
                "01": "#21263B",
                "02": "#3E3E66",
                "03": "#838393",
                "04": "#D2D3DD",
                "05": "#A4A4B7",
                "06": "#E5E5E5",
                light: "#DDDEF2",
            },
            blue: "#1F1F56",
            "blue-light": "#C5CEFF",
            bellflower: "#6367FF",
            "bellflower-light": "#7275FF",
            red: "#ce3945",
        },
    },
    plugins: [
        plugin(({ addVariant }) => {
            addVariant("children", "& > *");
            addVariant("children-hover", "& > *:hover");
            addVariant("child", "& > :first-child");
            addVariant("child-hover", "& > :first-child:hover");
            addVariant("child-last", "& > :last-child");
            addVariant("child-last-hover", "& > :last-child:hover");
        }),
        require("tailwind-scrollbar-hide"),
    ],
};

export default config;
