import type { AppDispatch } from "./store";

import {
    DefaultRootState,
    TypedUseSelectorHook,
    useDispatch,
    useSelector,
} from "react-redux";

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<DefaultRootState> =
    useSelector;
