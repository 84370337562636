import { cva, VariantProps } from "class-variance-authority";
import { forwardRef } from "react";

import * as utils from "@Modules/common/utils";

type TextVariants = VariantProps<typeof textVariants>;

const textVariants = cva([""], {
    variants: {
        variant: {
            h1: "text-h1-m md:text-h1",
            h2: "text-h2-m md:text-h2",
            h3: "text-h3-m md:text-h3",
            h4: "text-h4-m md:text-h4",
            h5: "text-h5",
            p1: "text-p1-m md:text-p1",
            p2: "text-p2",
            p3: "text-p3",
            mini: "text-mini",
        },
        weight: {
            400: "font-normal",
            500: "font-medium",
            600: "font-semibold",
            700: "font-bold",
        },
        uppercase: {
            true: "uppercase",
        },
        align: {
            left: "text-left",
            center: "text-center",
            right: "text-right",
        },
        color: {
            transparent: "text-transparent",
            current: "text-current",
            totalBlack: "text-total-black",
            black: "text-black",
            white: "text-white",
            steel01: "text-steel-01",
            steel02: "text-steel-02",
            steel03: "text-steel-03",
            steel04: "text-steel-04",
            steel05: "text-steel-05",
            steel06: "text-steel-06",
            steelLight: "text-steel-light",
            blue: "text-blue",
            blueLight: "text-blue-light",
            bellflower: "text-bellflower",
            bellflowerLight: "text-bellflower-light",
        },
        wysiwyg: {
            true: "wysiwyg",
        },
    },
    defaultVariants: {
        variant: "p1",
        weight: 400,
    },
});

const text = (variants: TextVariants) => textVariants(variants);

type TextProps = TextVariants &
    React.PropsWithChildren<{
        /**
         * @default div
         */
        tag?: "span" | "p" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
        className?: string;
        html?: string;
    }>;

export const Text = forwardRef<HTMLDivElement, TextProps>(
    ({ tag, className, html, children, ...props }, ref) => {
        const Component = tag || "div";

        return (
            <Component
                ref={ref}
                className={utils.twcx(text(props), className)}
                {...(html ? { dangerouslySetInnerHTML: { __html: html } } : {})}
            >
                {children}
            </Component>
        );
    }
);

Text.displayName = "Text";
