type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.0693 8.50989L10.5093 4.22989C4.75929 1.34989 2.39929 3.70989 5.27929 9.45989L6.14929 11.1999C6.39929 11.7099 6.39929 12.2999 6.14929 12.8099L5.27929 14.5399C2.39929 20.2899 4.74929 22.6499 10.5093 19.7699L19.0693 15.4899C22.9093 13.5699 22.9093 10.4299 19.0693 8.50989ZM15.8393 12.7499H10.4393C10.0293 12.7499 9.68929 12.4099 9.68929 11.9999C9.68929 11.5899 10.0293 11.2499 10.4393 11.2499H15.8393C16.2493 11.2499 16.5893 11.5899 16.5893 11.9999C16.5893 12.4099 16.2493 12.7499 15.8393 12.7499Z"
            fill="currentColor"
        />
    </svg>
);

export default Icon;
