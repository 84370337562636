import { useEffect, useState } from "react";

export const useDelayedTrigger = (
    isTriggered: boolean,
    delayMs: number = 500
) => {
    const [done, setIsDone] = useState(false);

    useEffect(() => {
        let doneTimeout: NodeJS.Timeout | null = null;

        if (isTriggered) {
            doneTimeout = setTimeout(() => setIsDone(true), delayMs);
        } else {
            setIsDone(false);
        }

        return () => {
            doneTimeout && clearTimeout(doneTimeout);
        };
    }, [isTriggered, delayMs]);

    return done;
};
