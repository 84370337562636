import { forwardRef, PropsWithChildren } from "react";

import * as utils from "@Utils";

type ContainerProps = PropsWithChildren & {
    className?: string;
    grid?: boolean;
};

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
    ({ className, grid, children }, ref) => {
        return (
            <div
                ref={ref}
                className={utils.twcx(
                    "mx-auto w-full px-3 lg:max-w-[1180px] lg:px-0",
                    {
                        "grid grid-cols-3 gap-x-3 sm:grid-cols-8 md:grid-cols-12 md:gap-x-4":
                            grid,
                    },
                    className
                )}
            >
                {children}
            </div>
        );
    }
);

Container.displayName = "Container";
