"use client";

import { usePathname } from "next/navigation";
import React, { useContext, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import { useKeyPressEvent, useUpdateEffect } from "react-use";

import { ButtonIcon } from "@Components/Button";
import { Icon } from "@Components/SvgIcon";
import { useAppDispatch, useAppSelector } from "@Core/store";
// import useScrollLock from "@Hooks/scroll-lock";
import { close, create, getIsOpen } from "@Modals";
import * as utils from "@Utils";

import css from "./modal.module.scss";

export type ModalProps = React.PropsWithChildren & {
    className?: string;
    classNameOverlay?: string;
    classNameContainer?: string;
    classNameButtonClose?: string;
    id: string;
    closeIcon?: Icon;
    animate?: "SlideUp" | "SlideDown";
};

const ModalContext = React.createContext({
    close: () => {
        return;
    },
});

export const useModalContext = () => useContext(ModalContext);

export const Modal: React.FC<ModalProps> = ({
    className,
    classNameOverlay,
    classNameContainer,
    classNameButtonClose,
    id,
    closeIcon = "close",
    animate,
    children,
}) => {
    const refComponent = useRef<HTMLDivElement>(null);
    const refContainer = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getIsOpen(id));
    const pathname = usePathname(); // check for [slug] pages
    // const scrollLock = useScrollLock(`popup-${id}`);

    const handleCloseClick = () => dispatch(close(id));

    useEffect(() => {
        dispatch(create(id));
    }, []);

    useUpdateEffect(() => {
        dispatch(close(id));
    }, [pathname]);

    // useEffect(() => {
    //     if (isOpen) {
    //         scrollLock.lock();
    //     } else {
    //         scrollLock.unlock();
    //     }
    // }, [isOpen]);

    useKeyPressEvent("Escape", () => dispatch(close(id)));

    const handleOverlayClick = () => dispatch(close(id));

    return createPortal(
        <ModalContext.Provider
            value={{
                close: handleCloseClick,
            }}
        >
            <CSSTransition
                nodeRef={refComponent}
                in={isOpen}
                timeout={350}
                unmountOnExit
            >
                <div
                    ref={refComponent}
                    className={utils.twcx(
                        "fixed inset-0 z-50 flex bg-black bg-opacity-60",
                        css.component,
                        { [css[`component_animate${animate}`]]: animate },
                        className
                    )}
                >
                    <div
                        className={utils.twcx(
                            "absolute inset-0",
                            classNameOverlay
                        )}
                        onClick={handleOverlayClick}
                    ></div>

                    <div
                        ref={refContainer}
                        className={utils.twcx(
                            "relative",
                            css.component__container,
                            classNameContainer
                        )}
                    >
                        <ButtonIcon
                            className={utils.twcx(
                                "absolute right-3 top-3 z-20 text-black hover:opacity-80 sm:right-5 sm:top-5",
                                classNameButtonClose
                            )}
                            size="xsm"
                            variant="empty"
                            icon={closeIcon}
                            aria-label="Close"
                            // aria-label={t("popupButtonCloseAria")}
                            onClick={handleCloseClick}
                        />
                        {children}
                    </div>
                </div>
            </CSSTransition>
        </ModalContext.Provider>,
        document.body
    );
};
