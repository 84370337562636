type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.6 20H14.4C18.4 20 20 18.4 20 14.4V9.6C20 5.6 18.4 4 14.4 4H9.6C5.6 4 4 5.6 4 9.6V14.4C4 18.4 5.6 20 9.6 20Z"
            fill="transparent"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5 17.21L8.5043 14.8559C9.06585 14.479 9.87617 14.5217 10.3808 14.9555L10.6154 15.1618C11.1698 15.6383 12.0655 15.6383 12.6199 15.1618L15.5769 12.6228C16.1313 12.1463 17.0269 12.1463 17.5814 12.6228L18.74 13.6185M9.49944 10.8448C9.87648 10.8448 10.2381 10.6949 10.5047 10.4282C10.7713 10.1614 10.9211 9.79964 10.9211 9.42239C10.9211 9.04515 10.7713 8.68336 10.5047 8.41661C10.2381 8.14986 9.87648 8 9.49944 8C9.1224 8 8.76081 8.14986 8.4942 8.41661C8.22759 8.68336 8.07782 9.04515 8.07782 9.42239C8.07782 9.79964 8.22759 10.1614 8.4942 10.4282C8.76081 10.6949 9.1224 10.8448 9.49944 10.8448Z"
            fill="transparent"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Icon;
