import cx from "clsx";
import React from "react";

import {
    SvgIcon,
    Icon as SvgIconName,
    SvgIconProps,
} from "@Components/SvgIcon";

export type TextIconProps = React.HtmlHTMLAttributes<HTMLSpanElement> & {
    classNameIcon?: string;
    classNameText?: string;
    icon: SvgIconName;
    iconSize?: SvgIconProps["size"];
    isRight?: boolean;
};

export const TextIcon: React.FC<TextIconProps> = ({
    className,
    classNameIcon,
    classNameText,
    icon,
    iconSize,
    isRight,
    children,
    ...props
}) => {
    return (
        <span
            className={cx(className, "flex items-center gap-1", {
                "flex-row-reverse": isRight,
            })}
            {...props}
        >
            <SvgIcon
                className={cx(classNameIcon, "shrink-0")}
                icon={icon}
                size={iconSize}
            />

            <span className={classNameText}>{children}</span>
        </span>
    );
};
