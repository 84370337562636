import { useField } from "formik";

import { Input, InputProps } from "./Input";

type FormikInputProps = InputProps & {
    name: string;
};

export const FormikInput: React.FC<FormikInputProps> = ({ name, ...props }) => {
    const [field, meta] = useField(name);
    const error = meta.touched && meta.error ? meta.error : null;

    return (
        <Input
            {...field}
            {...props}
            error={error}
            onChange={e => {
                field.onChange(e);
                // props.onChange?.(e);
            }}
        />
    );
};
