type IconProps = {
    className?: string;
};

const Icon: React.FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.375 4.92878L11.8918 10.4452L6.50021 5.05363C6.03043 4.58385 5.27406 4.58385 4.80428 5.05363C4.33465 5.52326 4.3345 6.27978 4.80428 6.74956L10.196 12.1081L4.92898 17.3751C4.4592 17.8449 4.4592 18.6013 4.92898 19.0711C5.39861 19.5407 6.15498 19.5407 6.62476 19.0709L11.8835 13.8122L17.2588 19.1875C17.7283 19.6569 18.4849 19.6572 18.9547 19.1875C19.4245 18.7177 19.4242 17.961 18.9547 17.4915L13.5796 12.1164L19.0711 6.62486C19.5409 6.15508 19.5406 5.39841 19.0711 4.92893C18.6012 4.459 17.8406 4.46323 17.375 4.92878Z"
            fill="currentColor"
        />
    </svg>
);

export default Icon;
