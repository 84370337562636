import { cva, VariantProps } from "class-variance-authority";
import React, { forwardRef } from "react";

import { Link, LinkProps } from "@Components/Link";
import { Icon as SvgIconName, SvgIconProps } from "@Components/SvgIcon";
import { TextIcon } from "@Components/TextIcon";
import * as utils from "@Modules/common/utils";

type ButtonVariants = VariantProps<typeof buttonVariants>;

const buttonVariants = cva(
    [
        "relative z-0 inline-flex cursor-pointer items-center justify-center whitespace-nowrap border-none p-3 text-center font-medium outline-none transition md:hover:bg-bellflower-light md:hover:text-white",
    ],
    {
        variants: {
            /**
             * @default primary
             */
            variant: {
                primary: "bg-white text-blue",
                secondary: "bg-bellflower text-white",
                outline:
                    "border border-solid border-blue bg-transparent text-blue md:hover:border-bellflower-light",
                empty: "bg-transparent",
            },
            size: {
                xsm: "h-6",
                sm: "h-9 min-w-[122px] rounded-lg text-p3 md:h-10 md:min-w-[152px] md:rounded-xl",
                md: "h-11 min-w-[200px] rounded-xl text-p2 md:h-[52px] md:min-w-[249px]",
                lg: "h-12 min-w-[200px] rounded-xl text-p1-m md:h-16 md:min-w-[249px] md:rounded-2xl md:text-p1",
            },
            block: {
                true: "flex w-full",
            },
            disabled: {
                true: "pointer-events-none cursor-not-allowed opacity-40",
            },
        },
        defaultVariants: {
            variant: "primary",
            size: "lg",
        },
    }
);

const button = (variants: ButtonVariants) => buttonVariants(variants);

export type ButtonProps = ButtonVariants &
    LinkProps &
    React.PropsWithChildren<{
        className?: string;
        icon?: SvgIconName;
        iconSize?: SvgIconProps["size"];
        iconIsRight?: boolean;
        disabled?: boolean;
        type?: HTMLButtonElement["type"];
    }>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            icon,
            iconSize = "lg",
            iconIsRight,
            disabled,
            type = "button",
            block,
            children,
            ...props
        },
        ref
    ) => {
        const commonProps = {
            ref,
            ...props,
            className: utils.twcx(
                button({ disabled, block, ...props }),
                className
            ),
        };

        const content = icon ? (
            <TextIcon icon={icon} iconSize={iconSize} isRight={iconIsRight}>
                {children}
            </TextIcon>
        ) : (
            children
        );

        if (props.href || props.phone || props.email) {
            return <Link {...commonProps}>{content}</Link>;
        }

        return (
            <button {...commonProps} type={type} disabled={disabled}>
                {content}
            </button>
        );
    }
);

Button.displayName = "Button";
