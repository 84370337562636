import { useEffect, useRef, useState } from "react";

import { useDelayedTrigger } from "@Modules/common/hooks/delayedTrigger";

export const useAsyncButton = (
    onClick: (e: React.MouseEvent) => Promise<void>
) => {
    const mountedRef = useRef(false);
    const [isLoading, setIsLoading] = useState(false);
    const showSpinner = useDelayedTrigger(isLoading);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    const handleClick = async (e: React.MouseEvent) => {
        setIsLoading(true);

        await onClick(e);

        if (mountedRef.current) {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        showSpinner,
        handleClick,
    };
};
