import {
    Action,
    combineReducers,
    configureStore,
    ThunkAction,
} from "@reduxjs/toolkit";

import { IS_DEV } from "@Settings";

import createRootReducer from "./reducers";

const combinedReducer = combineReducers(createRootReducer());

function makeStore() {
    return configureStore({
        devTools: IS_DEV,
        reducer: combinedReducer,
        middleware: getDefaultMiddleware => {
            return getDefaultMiddleware({
                immutableCheck: false,
                thunk: true,
                serializableCheck: {
                    ignoredActions: ["modals/open"],
                    ignoredPaths: ["modals"],
                },
            });
        },
    });
}

export const store = makeStore();

type RootState = ReturnType<typeof combinedReducer>;
declare module "react-redux" {
    interface DefaultRootState extends RootState {}
}

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
