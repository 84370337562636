import { initContract } from "@ts-rest/core";
import { initNextClient } from "@ts-rest/next";
import { z } from "zod";

const API_URL = process.env.NEXT_PUBLIC_API_URL || "/api";
const c = initContract();

export const AddContactsSchema = z.object({
    name: z.string(),
    email: z.string().email(),
    phone: z.string(),
});

const router = c.router({
    addContacts: {
        path: "/register",
        method: "POST",
        body: AddContactsSchema,
        responses: {
            201: null,
        },
    },
});

export const apiClient = initNextClient(router, {
    baseUrl: API_URL,
    baseHeaders: {},
});
