export const PAGES = {
    home: {
        link: "/",
        sections: {
            about: {
                link: "/#about-us",
            },
            advantages: {
                link: "/#advantages",
            },
            productOptions: {
                link: "/#product-options",
            },
            platforms: {
                link: "/#platforms",
            },
        },
    },
    policy: {
        link: "#",
    },
    terms: {
        link: "#",
    },
};
