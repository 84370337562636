import { cva, VariantProps } from "class-variance-authority";

import * as utils from "@Modules/common/utils";

import CheckIcon from "./icons/Check";
import CloseIcon from "./icons/Close";
import ImageIcon from "./icons/Image";
import SendIcon from "./icons/Send";
import styles from "./SvgIcon.module.scss";

type SvgIconVariants = VariantProps<typeof svgIconVariants>;

const svgIconVariants = cva(["block h-[1em] w-[1em] text-[1em] leading-none"], {
    variants: {
        size: {
            sm: "text-[16px]",
            md: "text-[20px]",
            lg: "text-[24px]",
        },
    },
});

const svgIcon = (variants: SvgIconVariants) => svgIconVariants(variants);

export type Icon = "close" | "check" | "image" | "send";

export type SvgIconProps = SvgIconVariants & {
    className?: string;
    icon: Icon;
};

const ICON_TO_COMPONENT: Record<Icon, React.FC<{ className?: string }>> = {
    close: CloseIcon,
    check: CheckIcon,
    image: ImageIcon,
    send: SendIcon,
};

export const SvgIcon: React.FC<SvgIconProps> = ({
    className,
    icon,
    ...props
}) => {
    const Component = ICON_TO_COMPONENT[icon];

    if (!Component) {
        return null;
    }

    return (
        <Component
            className={utils.twcx(styles.component, svgIcon(props), className)}
        />
    );
};
