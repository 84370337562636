import type { DefaultRootState } from "react-redux";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as _ from "lodash-es";

type PayloadCreate = string;

type PayloadOpen = {
    id: string;
    extra?: object;
};

type PayloadClose = string;

const initialState: { [key: string]: true | object | null } = {};

const slice = createSlice({
    name: "modals",
    initialState,
    reducers: {
        create(state, action: PayloadAction<PayloadCreate>) {
            if (typeof state[action?.payload] === "undefined") {
                state[action.payload] = null;
            }
        },
        open(state, action: PayloadAction<PayloadOpen>) {
            if (typeof state[action?.payload?.id] !== "undefined") {
                state[action.payload.id] = action.payload.extra ?? true;
            }
        },
        close(state, action: PayloadAction<PayloadClose>) {
            if (typeof state[action?.payload] !== "undefined") {
                state[action.payload] = null;
            }
        },
    },
});

export const getIsOpen = (id: string) => (state: DefaultRootState) =>
    !!state.modals[id];

export const getExtra =
    <T = unknown>(id: string) =>
    (state: DefaultRootState) =>
        _.isObject(state.modals[id]) ? (state.modals[id] as T) : null;

export const { actions, reducer: modalsReducer } = slice;

export const { create, open, close } = actions;
