"use client";

import { Button, ButtonProps } from ".";

import { useAsyncButton } from "./hooks/asyncButton";

interface ButtonAsyncProps extends ButtonProps {
    onClick: (e: React.MouseEvent) => Promise<void>;
}

export const ButtonAsync: React.FC<ButtonAsyncProps> = ({
    onClick,
    disabled,
    children,
    ...props
}) => {
    const { isLoading, showSpinner, handleClick } = useAsyncButton(onClick);

    return (
        <Button
            disabled={isLoading || disabled}
            onClick={handleClick}
            {...props}
        >
            {showSpinner ? (
                <span className="loader text-[1em]"></span>
            ) : (
                children
            )}
        </Button>
    );
};
