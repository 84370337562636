import config from "@/tailwind.config";
import tailwindConfig from "@/tailwind.config";
import cx, { ClassValue } from "clsx";
import * as _ from "lodash-es";
import { extendTailwindMerge } from "tailwind-merge";
import resolveTailwindConfig from "tailwindcss/resolveConfig";

const theme = resolveTailwindConfig(tailwindConfig).theme;

const twMerge = extendTailwindMerge({
    extend: {
        classGroups: {
            "font-size": Object.keys(config.theme?.fontSize || {}).map(
                key => `text-${key}`
            ),
            "text-color": Object.keys(config.theme?.colors || {}).map(
                key => `text-${key}`
            ),
        },
    },
});

export const twcx = (...args: ClassValue[]) => twMerge(cx(args));

export const delay = (ms: number, deltaMs?: number) =>
    new Promise(resolve => {
        setTimeout(
            resolve,
            deltaMs && deltaMs > 0
                ? ms + Math.round(Math.random() * deltaMs)
                : ms
        );
    });

export const normalizePhone = (phone: string) => {
    return phone && String(phone).replaceAll(/[^0-9+]/g, "");
};

const normalizedPhoneRx = /(\+7|7|8)([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})/;

export const printPhone = (phone: string | null) => {
    const m = phone?.match(normalizedPhoneRx);

    if (m && m?.length > 0) {
        return `${m[1]} ${m[2]} ${m[3]} ${m[4]} ${m[5]}`;
    }

    return phone;
};

export const getThemeColor = (path: string) => {
    return String(_.get(theme?.colors, path)) || undefined;
};

export const getThemeScreens = (path: string) => {
    return String(_.get(theme?.screens, path)) || undefined;
};

export const getIdFromLink = (link: string) => {
    return link.split("#").at(-1);
};

export const getHeaderHeight = (): number =>
    parseInt(
        document.documentElement.style.getPropertyValue("--header-height"),
        10
    ) || 0;
