import { SvgIcon } from "@Components/SvgIcon";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import { close, open } from "@Modals";
import { Modal } from "@Modals/Modal";

const MODAL_NAME = "thank_you";

export const openThankYouModal = () => open({ id: MODAL_NAME });

export const closeThankYouModal = () => close(MODAL_NAME);

const ThankYouModal: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Modal
            id={MODAL_NAME}
            className="items-center justify-center"
            classNameContainer="w-full mx-4 rounded-[20px] sm:rounded-[30px] sm:max-w-[383px] bg-white px-3 pt-16 pb-9 sm:px-5 sm:py-7 sm:px-10"
        >
            <div className="flex flex-col items-center gap-6 sm:gap-5">
                <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-bellflower">
                    <SvgIcon icon="check" className="text-white" size="md" />
                </div>
                <Text
                    variant="h5"
                    weight={600}
                    className="text-center"
                    color="steel01"
                >
                    {t("thank_you.text")}
                </Text>
            </div>
        </Modal>
    );
};

export default ThankYouModal;
