import NextLink, { LinkProps as NextLinkProps } from "next/link";
import React, { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import * as utils from "@Modules/common/utils";

import { SimpleLink } from "./SimpleLink";

export type LinkProps = HTMLAttributes<HTMLElement> &
    Omit<NextLinkProps, "href"> & {
        href?: string;
        blank?: boolean;
        simple?: boolean;
        phone?: string;
        email?: string;
    };

export const Link = forwardRef<HTMLElement, LinkProps>(
    (
        {
            className,
            href,
            blank = false,
            simple = false,
            phone,
            email,
            children,
            ...props
        },
        ref
    ) => {
        let linkHref = href;
        let linkChildren = children;

        if (phone) {
            linkHref = `tel:${utils.normalizePhone(phone)}`;
            linkChildren = utils.printPhone(utils.normalizePhone(phone));
        } else if (email) {
            linkHref = `mailto:${email}`;
            linkChildren = email;
        }

        const commonLinkProps = {
            className: utils.twcx(
                phone || email ? "whitespace-nowrap" : "",
                className
            ),
            href: linkHref ?? "",
        };

        if (simple || phone || email) {
            return (
                <SimpleLink
                    ref={ref}
                    {...commonLinkProps}
                    blank={blank}
                    {...props}
                >
                    {linkChildren}
                </SimpleLink>
            );
        }

        return (
            <NextLink
                ref={ref as ForwardedRef<HTMLAnchorElement>}
                {...commonLinkProps}
                passHref
                {...props}
            >
                {linkChildren}
            </NextLink>
        );
    }
);

Link.displayName = "Link";
