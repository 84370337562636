"use client";

import { cva, VariantProps } from "class-variance-authority";
import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
import TextareaAutosize, {
    TextareaAutosizeProps,
} from "react-textarea-autosize";

import { SvgIcon, Icon as SvgIconName } from "@Components/SvgIcon";
import * as utils from "@Utils";

import css from "./Input.module.scss";

import "react-phone-input-2/lib/style.css";

type InputVariants = VariantProps<typeof inputVariants>;

const inputVariants = cva([
    "block h-11 w-full px-4 py-3",
    "peer rounded-xl bg-white  caret-bellflower-light transition-colors scrollbar-hide",
    "border border-steel-04 hover:border-bellflower-light focus:border-bellflower-light",
    "text-p2 font-normal text-steel-01 placeholder:text-steel-03",
]);

const input = (variants: InputVariants) => inputVariants(variants);

export type InputProps = InputVariants &
    React.InputHTMLAttributes<HTMLInputElement> &
    React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
        classNameInput?: string;
        classNameIcon?: string;
        classNameButtonClear?: string;
        label?: string;
        error?: string | null;
        icon?: SvgIconName;
        isTextarea?: boolean;
        isTextAreaAutoSize?: boolean;
        textareaAutoSizeProps?: TextareaAutosizeProps;
        clearButton?: boolean;
        value?: string;
        setValue?: (value: string) => void;
    };

export const Input: React.FC<InputProps> = forwardRef<
    HTMLInputElement,
    InputProps
>(
    (
        {
            className,
            classNameInput,
            classNameIcon,
            classNameButtonClear,
            id,
            label,
            error,
            icon,
            isTextarea,
            isTextAreaAutoSize,
            textareaAutoSizeProps,
            clearButton = true,
            value,
            setValue,
            ...props
        },
        ref
    ) => {
        const [isPhoneValid, setIsPhoneValid] = useState(false);
        const refInput = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

        const isButtonClearVisible = clearButton && value && setValue;
        const isPhone = props.type === "tel";
        const isDefaultInput = !isPhone && !isTextarea && !isTextAreaAutoSize;

        const inputProps = {
            ref: refInput,
            className: utils.twcx(
                input(props),
                {
                    "border-red": error,
                    "resize-none": isTextarea || isTextAreaAutoSize,
                    "h-[120px]": isTextarea && !isTextAreaAutoSize,
                    "focus:pr-10": isButtonClearVisible,
                    "pr-12": icon,
                    "pl-12": isPhone,
                },
                classNameInput
            ),
            id,
            value,
            ...props,
        };

        if (isPhone) {
            delete inputProps.value;
        }

        const handleButtonClearClick = () => {
            if (setValue) {
                setValue("");

                refInput.current?.focus();
            }
        };

        useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
            ref,
            () => refInput.current
        );

        return (
            <div
                className={utils.twcx(
                    "flex flex-col gap-1.5 sm:gap-2",
                    {
                        "cursor-not-allowed opacity-70": props.disabled,
                    },
                    className
                )}
            >
                {(label || error) && (
                    <div
                        className={utils.twcx(
                            "flex items-end justify-between gap-x-2 text-p2 font-medium",
                            {
                                "pointer-events-none": props.disabled,
                            }
                        )}
                    >
                        {label && (
                            <label
                                className="flex-1 cursor-pointer text-steel-01"
                                htmlFor={id}
                            >
                                {label}
                            </label>
                        )}

                        {error && (
                            <label
                                className="flex-1 cursor-pointer text-right text-red"
                                htmlFor={id}
                            >
                                {error}
                            </label>
                        )}
                    </div>
                )}

                <div
                    className={utils.twcx("relative", {
                        "pointer-events-none": props.disabled,
                    })}
                >
                    {isPhone && (
                        <PhoneInput
                            disabled={inputProps.disabled}
                            country="ae"
                            preferredCountries={["ae", "us"]}
                            enableSearch
                            disableSearchIcon
                            masks={{
                                ae: "... ...-..-..",
                            }}
                            searchPlaceholder="Country or code"
                            searchNotFound="Not found"
                            specialLabel={""}
                            value={value as string}
                            inputProps={{
                                ...inputProps,
                                "data-is-valid": isPhoneValid,
                            }}
                            isValid={(inputNumber, country) => {
                                setTimeout(() => {
                                    setIsPhoneValid(
                                        (country as CountryData).format
                                            .split("")
                                            .filter((s: string) => s === ".")
                                            .length === inputNumber.length
                                    );
                                }, 0);

                                return true;
                            }}
                            containerClass={css.phone}
                            buttonClass={utils.twcx(css.phone__dropdownButton, {
                                isHidden: inputProps.disabled,
                            })}
                            dropdownClass={css.phone__dropdown}
                            searchClass={css.phone__dropdownSearch}
                        />
                    )}

                    {isTextarea && !isTextAreaAutoSize && (
                        <textarea {...inputProps}></textarea>
                    )}

                    {isTextAreaAutoSize && (
                        <TextareaAutosize
                            maxRows={3}
                            {...inputProps}
                            {...textareaAutoSizeProps}
                            style={{
                                ...props.style,
                                height: props.style?.height
                                    ? parseInt(String(props.style.height), 10)
                                    : undefined,
                            }}
                        />
                    )}

                    {isDefaultInput && <input {...inputProps} />}

                    {icon && (
                        <SvgIcon
                            className={utils.twcx(
                                "pointer-events-none absolute right-3 top-2.5 block text-steel-04",
                                {
                                    "peer-focus:hidden": isButtonClearVisible,
                                },
                                classNameIcon
                            )}
                            icon={icon}
                            size="lg"
                        />
                    )}

                    {isButtonClearVisible && (
                        <button
                            className={utils.twcx(
                                "clickable-space absolute right-3 top-3.5 block leading-none text-steel-04 opacity-0 transition-colors hover:text-steel-01 peer-focus:opacity-100",
                                classNameButtonClear
                            )}
                            type="button"
                            onClick={handleButtonClearClick}
                        >
                            <SvgIcon icon="close" size="sm" />
                        </button>
                    )}
                </div>
            </div>
        );
    }
);

Input.displayName = "Input";
