import * as _ from "lodash-es";
import { useEffect, useState } from "react";

import { getThemeScreens } from "@Utils";

enum Orientation {
    Portrait = 0,
    Landscape = 1,
}

interface WindowSize {
    width?: number;
    height?: number;
    isXs?: boolean;
    isSm?: boolean;
    isMd?: boolean;
    isLg?: boolean;
    isXl?: boolean;
    is2xl?: boolean;
    is3xl?: boolean;
    isPortrait?: boolean;
}

const getScreenOrientation = (width: number, height: number) =>
    width / height > 1 ? Orientation.Landscape : Orientation.Portrait;

const isBP = (size: string) =>
    window.innerWidth >= parseInt(String(getThemeScreens(size)), 10);

const getWindowSize = (): WindowSize => {
    const isXs = isBP("xs");
    const isSm = isBP("sm");
    const isMd = isBP("md");
    const isLg = isBP("lg");
    const isXl = isBP("xl");
    const is2xl = isBP("2xl");
    const is3xl = isBP("3xl");

    const screenOrientation = getScreenOrientation(
        window.innerWidth,
        window.innerHeight
    );

    return {
        width: window.innerWidth,
        height: window.innerHeight,
        isXs,
        isSm,
        isMd,
        isLg,
        isXl,
        is2xl,
        is3xl,
        isPortrait: screenOrientation === Orientation.Portrait,
    };
};

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: undefined,
        height: undefined,
        isXs: undefined,
        isSm: undefined,
        isMd: undefined,
        isLg: undefined,
        isXl: undefined,
        is2xl: undefined,
        is3xl: undefined,
        isPortrait: undefined,
    });

    useEffect(() => {
        const handleResize = _.debounce(() => {
            setWindowSize(getWindowSize());
        }, 50);

        window.addEventListener("resize", handleResize, { passive: true });

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
};

export default useWindowSize;
