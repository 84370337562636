import ContactUsModal from "./ContactUsModal";
import ThankYouModal from "./ThankYouModal";

const Modals: React.FC = () => (
    <>
        <ContactUsModal />
        <ThankYouModal />
    </>
);

export default Modals;
