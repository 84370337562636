"use client";

import { ButtonIcon, ButtonIconProps } from ".";

import { useAsyncButton } from "./hooks/asyncButton";

interface ButtonIconAsyncProps extends ButtonIconProps {
    onClick: (e: React.MouseEvent) => Promise<void>;
}

export const ButtonIconAsync: React.FC<ButtonIconAsyncProps> = ({
    onClick,
    disabled,
    children,
    ...props
}) => {
    const { isLoading, showSpinner, handleClick } = useAsyncButton(onClick);

    return (
        <ButtonIcon
            disabled={isLoading || disabled}
            onClick={handleClick}
            {...props}
        >
            {showSpinner ? (
                <span className="loader text-[1em]"></span>
            ) : (
                children
            )}
        </ButtonIcon>
    );
};
