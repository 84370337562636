export const DEFAULT_LANGUAGE = "en";
export const DEFAULT_NS = "common";

export function getOptions() {
    return {
        // debug: true,
        supportedLngs: [DEFAULT_LANGUAGE],
        fallbackLng: DEFAULT_LANGUAGE,
        lng: DEFAULT_LANGUAGE,
        fallbackNS: DEFAULT_NS,
        defaultNS: DEFAULT_NS,
        ns: ["common", "home"],
    };
}
