import { Button, ButtonProps } from ".";
import { cva, VariantProps } from "class-variance-authority";
import { forwardRef } from "react";

import * as utils from "@Utils";

import { SvgIcon, Icon as SvgIconName } from "../SvgIcon";

type ButtonIconVariants = VariantProps<typeof buttonIconVariants>;

const buttonIconVariants = cva(["min-w-0 p-0"], {
    variants: {
        size: {
            xsm: "w-6",
            sm: "w-9 md:w-10",
            md: "w-11 md:w-[52px]",
            lg: "w-12 md:w-16",
        },
    },
    defaultVariants: {
        size: "lg",
    },
});

const buttonIcon = (variants: ButtonIconVariants) =>
    buttonIconVariants(variants);

export type ButtonIconProps = ButtonProps &
    ButtonIconVariants & {
        className?: string;
        classNameIcon?: string;
        icon: SvgIconName;
    };

export const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
    (
        { className, classNameIcon, icon, iconSize = "lg", children, ...props },
        ref
    ) => {
        return (
            <Button
                ref={ref}
                className={utils.twcx(buttonIcon(props), className)}
                {...props}
            >
                {children || (
                    <SvgIcon
                        className={classNameIcon}
                        icon={icon}
                        size={iconSize}
                    />
                )}
            </Button>
        );
    }
);

ButtonIcon.displayName = "ButtonIcon";
