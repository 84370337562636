import React, { ForwardedRef, forwardRef, HTMLAttributes } from "react";

export type SimpleLinkProps = HTMLAttributes<HTMLElement> & {
    href?: string;
    blank?: boolean;
};

export const SimpleLink = forwardRef<HTMLElement, SimpleLinkProps>(
    ({ className, href, blank, children, ...props }, ref) => {
        return (
            <a
                ref={ref as ForwardedRef<HTMLAnchorElement>}
                className={className}
                href={href}
                {...(blank && {
                    target: "_blank",
                    rel: "noopener noreferrer nofollow",
                })}
                {...props}
            >
                {children}
            </a>
        );
    }
);

SimpleLink.displayName = "SimpleLink";
