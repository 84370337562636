import { Form, Formik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { AddContactsSchema } from "@Api";
import { Button } from "@Components/Button";
import { FormikInput } from "@Components/Input/FormikInput";
import { Text } from "@Components/Typography";
import { useTranslation } from "@Core/i18n/client";
import { close, open } from "@Modals";
import { Modal } from "@Modals/Modal";
import { openThankYouModal } from "@Modals/ThankYouModal";

import { useAddContactsMutation } from "./hooks";

const MODAL_NAME = "contact_us";

export const openContactUsModal = () => open({ id: MODAL_NAME });

export const closeContactUsModal = () => close(MODAL_NAME);

type AddContactsValues = z.infer<typeof AddContactsSchema>;

const ContactUsModal: React.FC = () => {
    const dispatch = useDispatch();
    const { mutateAsync: addContacts } = useAddContactsMutation();
    const { t } = useTranslation();

    const handleSubmit = async (
        body: AddContactsValues,
        { resetForm }: FormikHelpers<AddContactsValues>
    ) => {
        try {
            await addContacts({ body });
            dispatch(openThankYouModal());
        } catch (err) {
            // todo
        } finally {
            resetForm();
            dispatch(closeContactUsModal());
        }
    };

    return (
        <Modal
            id={MODAL_NAME}
            className="items-center justify-center"
            classNameContainer="w-full mx-4 rounded-[20px] sm:rounded-[30px] sm:max-w-[383px] bg-white px-4 py-5 sm:px-5 sm:py-6"
        >
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                }}
                validationSchema={toFormikValidationSchema(AddContactsSchema)}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Text
                            className="mb-5 sm:mb-4"
                            variant="h5"
                            color="steel01"
                            weight={600}
                        >
                            {t("contact_us.title")}
                        </Text>
                        <div className="mb-6 flex flex-col gap-4">
                            <FormikInput
                                id="contact_us-name"
                                name="name"
                                placeholder={t("contact_us.name.placeholder")}
                                label={t("contact_us.name.label")}
                            />
                            <FormikInput
                                id="contact_us-email"
                                name="email"
                                type="email"
                                label={t("contact_us.email.label")}
                                placeholder={t("contact_us.email.placeholder")}
                            />
                            <FormikInput
                                id="contact_us-phone"
                                name="phone"
                                type="tel"
                                label={t("contact_us.phone.label")}
                            />
                        </div>
                        <Button
                            // disabled={isSubmitting}
                            type="submit"
                            variant="secondary"
                            size="md"
                            block
                            className="mb-4"
                        >
                            {t("contact_us.button")}
                        </Button>
                        <Text
                            variant="p3"
                            color="steel03"
                            className="text-center sm:px-3"
                        >
                            {t("contact_us.policy")}
                        </Text>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ContactUsModal;
